import React from 'react'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import ArticleList from '../components/home/article-list'

const TechnologyIndex = ({ data, location }) => (
  <ArticleList
    title="Technology"
    seo={{ title: 'Technology' }}
    barTitle="បច្ចេកវិទ្យា"
    data={data}
    location={location}
    pageNavigate={(pageNum) => navigate(`/technologies/${pageNum}`)}
  />
)

TechnologyIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              readingTime: PropTypes.shape({
                minutes: PropTypes.number.isRequired
              }).isRequired
            }),
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              author: PropTypes.number.isRequired,
              featuredImage: PropTypes.shape({
                id: PropTypes.string.isRequired,
                publicURL: PropTypes.string.isRequired,
                base: PropTypes.string.isRequired
              }),
              tags: PropTypes.array.isRequired
            }).isRequired
          })
        })
      ),
      totalCount: PropTypes.number,
      pageInfo: PropTypes.shape({
        currentPage: PropTypes.number,
        hasNextPage: PropTypes.bool,
        hasPreviousPage: PropTypes.bool,
        pageCount: PropTypes.number,
        perPage: PropTypes.number
      })
    })
  }),
  allFile: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          base: PropTypes.string.isRequired,
          childImageSharp: PropTypes.any
        })
      })
    )
  }),
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          nameInKhmer: PropTypes.string
        })
      )
    })
  })
}

export default TechnologyIndex

export const pageQuery = graphql`
  query TechnologyPage($limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { eq: "technology" }, published: { eq: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 625, truncate: true)
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            featuredImage {
              id
              publicURL
              base
            }
            tags
          }
        }
      }
      totalCount
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        perPage
      }
    }
    allFile(filter: { ext: { ne: ".md" } }) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 108, height: 108)
          }
        }
      }
    }
    site {
      siteMetadata {
        authors {
          name
          nameInKhmer
          id
        }
      }
    }
  }
`
